@import '../styles/mixins';
@import '../styles/variables';

.list {
  margin: 0 -5px;
  @include flex();
}

.wrap {
  flex-wrap: wrap;

  .item {
    flex: 1 0 30%;

    @media (max-width: $breakpoint - 1) {
      flex: 1 0 40%;
    }
  }
}

.column {
  flex-direction: column;
  align-items: stretch;
}

.item {
  margin: 5px;

  button {
    background: #e9edf8;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    white-space: nowrap;

    &:hover {
      background: darken(#e9edf8, 2%);
    }
  }
}
