@import '../styles/mixins';
@import '../styles/variables';

.component {
  text-align: center;
}

.header {
  margin: 10px 0 30px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.timestamp {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 20px;
}

.card {
  background: #ebeff8;
  border-radius: 8px;
  padding: 20px;
  padding-top: 30px;
}

.processing {
  @include flex;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;

  .item {
    margin-left: 5px;
  }
}

.icons {
  @include flex;
}

.icon {
  &:nth-child(2) {
    opacity: 0.66;
  }

  &:nth-child(3) {
    opacity: 0.33;
  }
}

.muted {
  opacity: 0.33;
}

.desc {
  font-size: 14px;
}

.footer {
  @include flex(space-between);
  border-top: 1px solid rgba(32, 67, 181, 0.2);
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  padding-top: 20px;
}
