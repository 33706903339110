@import '../styles/variables';
@import '../styles/mixins';

.button {
  @include flex;
}

.header {
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 500;
}

.list {
  @include flex;
  margin-bottom: 10px;
}

.item {
  $color: white;
  @include flex;

  flex: none;
  background-color: transparent;
  border: 1px solid $sky;
  border-radius: 15px;
  font-size: 12px;
  height: 30px;
  width: 60px;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    border-color: white;
  }
}

.input {
  text-align: right;

  &:not(.focused) {
    opacity: 0.3;
  }
}

.group {
  width: 100px;
  padding: 0 15px;

  input {
    flex: 1;
    width: 0;
    padding-right: 10px;
  }

  span {
    flex: none;
  }

  &.error {
    border-color: $red;
  }

  &.warning {
    border-color: $orange;
  }

  &.success {
    border-color: $blue;
  }
}

.active {
  background-color: white;
  border-color: white;
  color: $brand;
}

.feedback {
  font-size: 12px;
  text-align: right;

  &.error {
    color: $red;
  }

  &.warning {
    color: $orange;
  }
}
