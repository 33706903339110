@font-face {
  font-family: 'Gotham';
  src: url(Gotham-Light.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gotham';
  src: url(Gotham-Book.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';
  src: url(Gotham-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gotham';
  src: url(Gotham-Bold.otf) format('opentype');
  font-weight: 700;
}

/* Lato */
@font-face {
  font-family: 'Gotham';
  src: url(LatoLatin-Light.woff2) format('woff2');
  font-weight: 300;
  unicode-range: U+003?;
}

@font-face {
  font-family: 'Gotham';
  src: url(LatoLatin-Regular.woff2) format('woff2');
  font-weight: 400;
  unicode-range: U+003?;
}

@font-face {
  font-family: 'Gotham';
  src: url(LatoLatin-Bold.woff2) format('woff2');
  font-weight: 500;
  unicode-range: U+003?;
}

@font-face {
  font-family: 'Gotham';
  src: url(LatoLatin-Black.woff2) format('woff2');
  font-weight: 700;
  unicode-range: U+003?;
}
