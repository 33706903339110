@import '../styles/mixins';

.list {
  @include flex();
  flex-wrap: wrap;
}

.warning {
  margin-top: 10px;
}
