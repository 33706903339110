.inline {
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 5px;

  i {
    font-size: 20px;
  }
}

.percent {
  margin-top: 5px;
}
