@use 'sass:math';
@import '../styles/variables';

.item {
  width: math.div(100%, 6);
  padding-right: 10px;
  position: relative;
}

.suggestions {
  position: absolute;

  :nth-child(6n) > & {
    right: 0;
  }

  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  width: 160px;
  max-height: 120px;
  z-index: 1;

  button {
    font-size: 12px;
    font-weight: normal;
    padding: 0 15px;
    text-align: left;
    width: 100%;
  }

  &.hover button:hover,
  button.active {
    background: $sky;
    color: white;
    cursor: pointer;
  }
}

$margin: 20px;
$padding: 7px;

.number {
  font-size: 12px;
  position: absolute;
  transition: top 200ms ease;
  top: $margin;

  &.small {
    font-size: 9px;
    top: $padding;
  }
}

.input {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 12px;
  padding: $padding 0;
  margin-top: ($margin - $padding);
  width: 100%;
}
