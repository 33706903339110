.article {
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.title {
  font-size: 22px;
  font-weight: 500;
}

.main {
  font-size: 15px;
}

.actions {
  margin-top: 60px;
}

.footer {
  display: flex;
  flex: 1;

  > button {
    min-width: unset;
  }
}
