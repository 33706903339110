@import '../styles/variables';

.reference {
  cursor: default;
}

/* tooltip */
.pop,
.tooltip {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1000;
  min-width: 80px;
}

.tooltip {
  pointer-events: none;
}

.bottom {
  transform: translate(-50%, 0);
  margin-top: 20px;
}

.arrow {
  $size: 6px;
  content: '';
  display: block;
  width: (2 * $size);
  height: (2 * $size);
  border: $size solid transparent;
  margin: 0 auto;

  .top & {
    border-top-color: $brand;
  }

  .bottom & {
    border-bottom-color: $brand;
  }
}

.content {
  background: $brand;
  border-radius: 5px;
  color: white;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  white-space: pre-line;

  @media (max-width: $breakpoint - 1) {
    left: initial !important;
  }
}

.hidden {
  visibility: hidden;
}
