@import './styles/mixins';
@import './styles/variables';
@import './styles/fonts';
@import './styles/reboot';
@import './styles/components';

body:not(.extension) {
  @media (min-width: $breakpoint-large) {
    padding-left: $nav-width-large;
  }
}

.extension {
  @include flex;
}
