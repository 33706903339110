@import '../styles/mixins';
@import '../styles/variables';

.overlay {
  @include flex();

  background: fade-out(black, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1060;
}

.content {
  background: white;
  outline: 0;
  max-height: 100%;
  overflow-y: auto;

  @media (max-width: $breakpoint - 1) {
    padding: $gutter;
    height: 100%;
    width: 100%;
  }

  @media (min-width: $breakpoint) {
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0 fade-out(black, 0.8);
    padding: 15px;
    width: 540px;

    &:empty {
      padding: 0;
    }
  }
}
