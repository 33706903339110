@import '../styles/variables';
@import '../styles/mixins';

.footer {
  border-top: 1px solid $hairline;
  text-align: center;

  span {
    @include flex(flex-start);
    display: inline-flex;

    color: $sky;
    font-size: 13px;
  }

  i {
    margin-right: 8px;
  }
}

.address {
  font-size: 13px;
  padding: 30px 0;

  button {
    display: inline-block;
    font-weight: 500;
  }
}

.link {
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
  white-space: nowrap;
}
