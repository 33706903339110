@import '../styles/mixins';
@import '../styles/variables';

.content {
  @media (max-width: $breakpoint - 1) {
    padding: $gutter 0;
  }

  @media (min-width: $breakpoint) {
    padding: 25px $gutter-large;
    max-width: $max-width;
  }
}

.extension {
  background: white;
  width: 420px;
  min-height: 540px;

  form > header {
    text-align: left;
  }
}

.padding {
  padding: 20px;
}
