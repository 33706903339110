@import '../styles/mixins';
@import '../styles/variables';

.component {
  @include flex();

  @media (max-width: $breakpoint - 1) {
    flex-direction: column;
    text-align: center;
  }

  @media (min-width: $breakpoint) {
    justify-content: flex-start;
  }

  padding: 25px 0;

  .content {
    @media (min-width: $breakpoint) {
      margin-left: 20px;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: 500;

    @media (max-width: $breakpoint - 1) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 15px;
  }
}
