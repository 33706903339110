/* colors */
.text-primary {
  color: $brand;
}

.text-secondary {
  color: $sky;
}

.text-success {
  color: $green;
}

.text-warning {
  color: $orange;
}

.text-danger {
  color: $red;
}

.text-muted {
  color: $gray;
}

/* alignments */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* decoration */
.text-underline {
  text-decoration: underline;
}

/* transform */
.text-capitalize {
  text-transform: capitalize;
}

/* white-space */
.pre-line {
  white-space: pre-line;
}
