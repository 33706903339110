@import '../styles/mixins';
@import '../styles/variables';

.dl {
  font-size: 14px;
  margin-bottom: 20px;

  input {
    font-size: 13px;
    text-align: right;
  }

  select {
    font-size: 12px;
    margin-left: 5px;
    padding-left: 15px;
    padding-right: 30px;
  }
}

.feedback {
  @media (min-width: $breakpoint) {
    min-height: 120px;
  }

  margin-bottom: 20px;
}

.ledger {
  text-align: center;

  section {
    margin: 100px 0 70px;
  }

  p {
    margin-top: 15px;
  }
}
