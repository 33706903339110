@import '../styles/mixins';
@import '../styles/variables';

.title {
  @include flex(flex-start);
}

.button {
  @include flex;
  margin-left: 10px;
}

.table {
  margin-top: 20px;
}

.icon {
  margin-left: 5px;
}

.all {
  @include flex();

  background-color: $bg;
  border-radius: 45px;
  color: $brand;
  font-size: 15px;
  font-weight: 500;
  height: 64px;
  width: 100%;
}
