@import '../../styles/mixins';

.body {
  padding-top: 0;
}

.header {
  @include flex(flex-start);
  font-size: 13px;
  margin-bottom: 20px;
}
