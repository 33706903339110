.form {
  display: flex;

  > * {
    flex: 1;
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 0;
      margin-left: 15px;
    }
  }
}
