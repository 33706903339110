@import '../styles/mixins';

.overlay {
  @include flex();
  background: fade-out(black, 0.15);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  article {
    color: #fff;
    text-align: center;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    margin: 20px;
  }

  p {
    font-size: 15px;
  }
}
