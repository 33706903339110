@import '../styles/mixins';
@import '../styles/variables';

.heading {
  @media (min-width: $breakpoint) {
    justify-content: center;
  }

  @include flex(space-between);
}

.submit {
  @include flex;
  margin-top: 20px;

  @media (min-width: $breakpoint) {
    margin-top: 40px;
  }

  > button {
    flex: 1;
  }
}
