@import '../styles/mixins';
@import '../styles/variables';

$border-bottom: 1px solid fade-out(white, 0.9);

.nav {
  @include drag();
  background: $navy;
}

.nav:not(.extension) {
  @media (min-width: $breakpoint-large) {
    width: $nav-width-large;
    display: flex;
    flex-direction: column;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.header {
  @include flex(space-between, stretch);

  @media (max-width: $breakpoint-large - 1) {
    height: $nav-height;

    svg {
      width: 113px;
      height: 15px;
    }
  }

  .extension & svg {
    width: 90px;
    height: 12px;
  }

  @media (min-width: $breakpoint-large) {
    border-bottom: $border-bottom;
    height: $nav-height-large;
  }

  .extension & {
    height: $nav-height-extension;
  }

  .logo,
  .toggle {
    font-size: 1.5em;
    @include flex();
    color: white;

    @media (max-width: $breakpoint-large - 1) {
      padding: 0 20px;
    }

    @media (min-width: $breakpoint-large) {
      padding: 0 15px;
    }

    &:hover {
      text-decoration: none;
    }

    svg {
      fill: white;
    }
  }

  .logo {
    flex: 1;
    color: white;

    @media (max-width: $breakpoint-large - 1) {
      justify-content: flex-start;
    }

    @media (min-width: $breakpoint-large) {
      justify-content: center;

      .extension & {
        justify-content: flex-start;
      }
    }
  }

  .toggle {
    flex: none;
    font-size: 19px;

    @media (min-width: $breakpoint-large) {
      display: none;
    }
  }
}

.main {
  @include flex(space-between, stretch);
  flex-direction: column;
  background: $navy;

  @media (max-width: $breakpoint-large - 1) {
    display: none;
    padding-top: 30px;

    &.open {
      display: flex;
    }

    position: fixed;
    top: $nav-height;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
  }

  @media (min-width: $breakpoint-large) {
    flex: 1;
    display: flex;
    padding-top: 20px;
  }
}

.footer {
  color: white;

  .extension & {
    @include flex();
  }

  .extension & > * {
    margin-left: 0;
  }
}

.container {
  padding: 20px;

  i {
    margin-right: 5px;
  }
}

.chain {
  @include flex(space-between);
  background: darken($navy, 3%);
  padding: 20px;

  > div {
    flex: auto;
    margin-right: 10px;
  }
}

.config {
  @include flex(space-between);

  margin: -5px;

  > * {
    margin: 5px;
    width: 50%;
  }
}

.select {
  opacity: 0.75;

  select {
    border: 0;
    font-size: 12px;
  }

  &:hover {
    opacity: 1;
  }
}
