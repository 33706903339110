/* Colors */
$navy: #3e3e3e;
$brand: #000000;
$sky: #5493f7;
$gray: #9e9e9e;
$line: #e7ebf6;
$hairline: #f2f2f2;

$blue: $brand;

$green: #1daa8e;
$red: #ff5561;
$orange: #fd9a02;
$purple: #7a6ff0;

$bg: #f4f5fb;
$bg-hover: fade-out($brand, 0.98);

$yes: #6292ec;
$no: #ce4a6f;
$veto: #f19f4d;
$abstain: #a757f4;

/* Sizes */
$breakpoint: 768px;
$breakpoint-large: 1024px;
$gutter: 20px;
$gutter-large: 30px;
$radius: 5px;
$max-width: (1180px + (2 * $gutter-large));

/* Copmonents */
$nav-height: 55px;
$nav-height-extension: 50px;
$nav-height-large: 68px;
$nav-width-large: 240px;
