@import '../styles/mixins';
@import '../styles/variables';

.title {
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.list {
  @include flex();
}

.item {
  @include flex();
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  padding-bottom: 25px;

  svg {
    fill: $brand;
  }

  & + & {
    border-left: 1px solid $line;
  }
}

.label {
  font-size: 14px;
  font-weight: 500;
  margin-top: -15px;
}

.links {
  padding-top: 23px;

  svg {
    margin-bottom: 23px;
  }
}

.link {
  color: $brand;

  &:hover {
    text-decoration: none;
  }
}

.ext {
  background-color: $brand;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  padding: 0 8px;

  &:hover {
    text-decoration: none;
  }

  & + & {
    margin-left: 5px;
  }
}
