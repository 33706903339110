@import '../styles/variables';

.warn {
  color: $orange;
  border-radius: 8px;
  border: solid 1px rgba(253, 154, 2, 0.2);
  background-color: #fffaf2;
  padding: 10px;
  margin-top: 20px;
}
