.title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.list {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.link {
  color: unset;
}

.label {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
}
