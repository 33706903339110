@import '../styles/mixins';
@import '../styles/variables';

.title {
  font-size: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.content {
  padding: 30px 0 70px;
  text-align: center;

  > i {
    margin-bottom: 20px;
  }
}

.message {
  font-size: 75%;
}

.copy {
  @include flex();
  color: $sky;

  button {
    @include flex();
    margin-left: 4px;
  }
}
